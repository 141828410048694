import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '../../forms/inputs/Select';
import LanguageInput from '../../forms/inputs/LanguageInput';
import CountryProp from '../../forms/Address/CountryProp';
import FormInput from '../../forms/FormInput';
import Notifications from '../../../services/notifications';

function LanguageForm(props) {
  const {
    t,
    id,
    organizationLanguages,
    onTranslationChange,
    defaultLanguage,
    menuType,
    indexArray,
    setIsDraggable,
  } = props;
  return organizationLanguages.map(language => {
    const isDefaultLanguage =
      defaultLanguage?.toLowerCase() === language?.code?.toLowerCase();
    const label = !isDefaultLanguage
      ? language.name
      : `${t('name')} (${language.name})`;
    const value = menuType?.translations?.[language.code];
    return (
      <LanguageInput
        key={language?.code}
        id={`${id}-${language?.code}`}
        label={label}
        language={language}
        onChange={(field, l, v) => {
          onTranslationChange(menuType, l, indexArray, v);
        }}
        value={value}
        inputProps={{
          onFocus: () => setIsDraggable(false),
          onBlur: () => setIsDraggable(true),
        }}
      />
    );
  });
}

const MenuType = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  newTab: PropTypes.bool,
  position: PropTypes.number,
  url: PropTypes.string,
  userTypes: PropTypes.arrayOf(PropTypes.string),
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
      translations: PropTypes.shape({}),
      newTab: PropTypes.bool,
      url: PropTypes.string,
      userTypes: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  translations: PropTypes.shape({}),
});

function SubMenu(props) {
  const {
    menu,
    index,
    indexSubMenu,
    defaultLanguage,
    organizationLanguages,
    t,
    menuItems,
    setMenuItems,
    isSubMenu,
    onMoveUp,
    onMoveDown,
    setIsDraggable,
  } = props;

  const [showDetails, setShowDetails] = useState(true);
  const onFieldChange = (item, field, indexArray) => value => {
    const newMenuItems = [...menuItems];
    let valueParsed = value;
    if (field === 'newTab') {
      valueParsed = value.target.checked;
    }
    if (isSubMenu) {
      newMenuItems[index].subMenus[indexSubMenu] = {
        ...item,
        [field]: valueParsed,
      };
    } else {
      newMenuItems[indexArray] = {
        ...item,
        [field]: valueParsed,
      };
    }
    setMenuItems(newMenuItems);
  };

  const onTranslationChange = (item, language, indexArray, value) => {
    const newMenuItems = [...menuItems];
    if (isSubMenu) {
      newMenuItems[index].subMenus[indexSubMenu] = {
        ...item,
        name: item.translations[defaultLanguage],
        translations: {
          ...item.translations,
          [language]: value,
        },
      };
    } else {
      newMenuItems[indexArray] = {
        ...item,
        name: item.translations[defaultLanguage],
        translations: {
          ...item.translations,
          [language]: value,
        },
      };
    }
    setMenuItems(newMenuItems);
  };

  const onDistributorChange = (item, indexArray) => value => {
    const newMenuItems = [...menuItems];
    const isChecked = value.target.checked;
    if (isSubMenu) {
      newMenuItems[index].subMenus[indexSubMenu] = {
        ...item,
        userTypes: isChecked ? ['collaborator'] : [],
      };
    } else {
      newMenuItems[indexArray] = {
        ...item,
        userTypes: isChecked ? ['collaborator'] : [],
      };
    }
    setMenuItems(newMenuItems);
  };

  const addSubMenu = () => {
    const newMenuItems = [...menuItems];
    if (!newMenuItems[index].subMenus) {
      newMenuItems[index].subMenus = [];
    }
    newMenuItems[index].subMenus.push({
      position: newMenuItems[index].subMenus.length + 1,
      type: 'dynamic',
      name: '',
      translations: {},
      newTab: false,
      url: '',
      userTypes: [],
    });
    setMenuItems(newMenuItems);
  };

  const removeSubMenu = (menuIndex, subMenuIndexToRemove) => {
    const newMenuItems = [...menuItems];
    // remove the submenu
    const updatedSubMenus = [...newMenuItems[menuIndex].subMenus];
    updatedSubMenus.splice(subMenuIndexToRemove, 1);

    // update all positions
    const reorderedSubMenus = updatedSubMenus.map((subMenu, index) => ({
      ...subMenu,
      position: index + 1,
    }));

    // Update the menu item with new submenus
    newMenuItems[menuIndex] = {
      ...newMenuItems[menuIndex],
      subMenus: reorderedSubMenus,
    };

    setMenuItems(newMenuItems);
  };

  return (
    <li>
      {isSubMenu && (
        <div className="subMenu-title">
          <p
            style={{
              transform: showDetails ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          >
            &gt;&nbsp;
          </p>
          <p onClick={() => setShowDetails(!showDetails)}>
            {t('subMenu')}&nbsp;{menu.position}
          </p>
        </div>
      )}
      <div className="menus-content-container">
        {showDetails && (
          <div className="menus-content-languages">
            <LanguageForm
              t={t}
              id="language"
              onTranslationChange={onTranslationChange}
              organizationLanguages={organizationLanguages}
              defaultLanguage={defaultLanguage}
              menuType={menu}
              indexArray={index}
              setIsDraggable={setIsDraggable}
            />
          </div>
        )}
        {(!menu.subMenus || menu.subMenus.length === 0) && showDetails && (
          <div className="inputs">
            {menu.type === 'dynamic' && (
              <FormInput
                onChange={onFieldChange(menu, 'url', index)}
                id={`link-${index}-${indexSubMenu || '-1'}`}
                placeholder={t('link')}
                label={t('link')}
                value={menu.url || ''}
                labelClassName="col-4"
                containerClassName="col-8"
                inputProps={{
                  onFocus: () => setIsDraggable(false),
                  onBlur: () => setIsDraggable(true),
                }}
              />
            )}
            <FormInput
              type="checkbox"
              onChange={onFieldChange(menu, 'newTab', index)}
              id={`openTab-${index}-${indexSubMenu || '-1'}`}
              label={t('openInNewTab')}
              value={menu.newTab || false}
              labelClassName="col-4"
              containerClassName="col-4"
              groupProps={{ className: 'row' }}
              inputClassName="opportunitiesCheckbox"
            />
            <FormInput
              type="checkbox"
              onChange={onDistributorChange(menu, index)}
              id={`distributors-${index}-${indexSubMenu || '-1'}`}
              label={t('onlyDistributors')}
              value={menu?.userTypes?.includes('collaborator') || false}
              labelClassName="col-4"
              containerClassName="col-4"
              groupProps={{ className: 'row' }}
              inputClassName="opportunitiesCheckbox"
            />
          </div>
        )}
      </div>
      <div className="subMenus-container">
        {menu.subMenus?.length > 0 &&
          menu.subMenus.map((subMenu, indexSubMenu) => (
            <div className="subMenu-container" key={indexSubMenu}>
              <button
                type="button"
                className="btn btn-circle btn-primary move-down-button"
                onClick={() => onMoveDown(subMenu)}
              >
                <i className="fa fa-arrow-down" />
              </button>
              <button
                type="button"
                className="btn btn-circle btn-primary move-up-button"
                onClick={() => onMoveUp(subMenu)}
              >
                <i className="fa fa-arrow-up" />
              </button>
              <button
                type="button"
                className="btn btn-circle red delete-menu-button"
                onClick={() => removeSubMenu(index, indexSubMenu)}
              >
                <i className="fa fa-times" />
              </button>
              <ul className="subMenus">
                <SubMenu
                  menu={subMenu}
                  index={index}
                  indexSubMenu={indexSubMenu}
                  defaultLanguage={defaultLanguage}
                  organizationLanguages={organizationLanguages}
                  t={t}
                  menuItems={menuItems}
                  setMenuItems={setMenuItems}
                  isSubMenu
                  setIsDraggable={setIsDraggable}
                />
              </ul>
            </div>
          ))}
      </div>
      {menu.type === 'dynamic' && !isSubMenu && (
        <div>
          <button
            type="button"
            className="btn primary"
            onClick={addSubMenu}
            disabled={menu.subMenus?.length >= 6}
          >
            {t('addSubMenu')}
          </button>
        </div>
      )}
    </li>
  );
}

SubMenu.propTypes = {
  t: PropTypes.func.isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  menu: MenuType.isRequired,
  index: PropTypes.number.isRequired,
  indexSubMenu: PropTypes.number,
  menuItems: PropTypes.arrayOf(MenuType).isRequired,
  setMenuItems: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  isSubMenu: PropTypes.bool.isRequired,
  setIsDraggable: PropTypes.func.isRequired,
};

export default function OrganizationUserPortalMenus({
  t,
  userPortalMenus,
  organizationLanguages,
  defaultLanguage,
}) {
  const menus = userPortalMenus ? Object.values(userPortalMenus) : [];
  const [menuItems, setMenuItems] = useState(menus);

  const onFieldChange = (field, indexArray) => value => {
    setMenuItems(currentMenuItems => {
      const currentItem = currentMenuItems[indexArray];
      const newMenuItems = [...currentMenuItems];
      let subMenus = newMenuItems.subMenus;
      if (field === 'type') {
        subMenus = [];
      }
      newMenuItems[indexArray] = {
        ...currentItem,
        subMenus,
        [field]: value,
      };
      return newMenuItems;
    });
  };

  function swapItems(array, index1, index2) {
    const newArray = [...array];
    [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]];
    return newArray;
  }

  const [movingItemIndex, setMovingItemIndex] = useState(-1);
  const handleDragStart = (e, itemIndex) => {
    e.dataTransfer.effectAllowed = 'move';
    setMovingItemIndex(itemIndex);
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    if (targetIndex >= 0 && movingItemIndex >= 0) {
      setMenuItems(swapItems(menuItems, movingItemIndex, targetIndex));
    }
  };

  const menuTypes = useMemo(
    () => [
      {
        id: 'dashboard',
        text: t('menu_dashboard'),
      },
      {
        id: 'academy',
        text: t('menu_academy'),
      },
      {
        id: 'marketplace',
        text: t('menu_marketplace'),
      },
      {
        id: 'dynamic',
        text: t('menu_dynamic'),
      },
    ],
    [],
  );

  const addMenu = e => {
    e.preventDefault();
    const newMenuItems = [...menuItems];
    newMenuItems.push({
      name: '',
      type: 'dynamic',
      newTab: false,
      position: menuItems.length + 1,
      url: null,
      subMenus: [],
      translations: organizationLanguages.map(ol => ({
        [ol.code]: '',
      })),
    });
    setMenuItems(newMenuItems);
  };

  const removeMenu = menuIndex => {
    const newMenuItems = [...menuItems];
    newMenuItems.splice(menuIndex, 1);

    // update all positions
    const reorderedSubMenus = newMenuItems.map((menu, index) => ({
      ...menu,
      position: index + 1,
    }));

    setMenuItems(reorderedSubMenus);
  };

  const moveSubMenu = (item, direction) => subMenu => {
    const newMenuItems = [...menuItems];
    const menuIndex = newMenuItems.findIndex(m => m.position === item.position);
    const subMenuIndex = item.subMenus.findIndex(
      sm => sm.position === subMenu.position,
    );

    const isValidMove =
      direction === 'up'
        ? subMenuIndex > 0
        : subMenuIndex < item.subMenus.length - 1;

    if (isValidMove) {
      const updatedSubMenus = [...item.subMenus];
      const targetIndex =
        direction === 'up' ? subMenuIndex - 1 : subMenuIndex + 1;

      // Swap positions in array
      [updatedSubMenus[subMenuIndex], updatedSubMenus[targetIndex]] = [
        updatedSubMenus[targetIndex],
        updatedSubMenus[subMenuIndex],
      ];

      // Update position numbers
      const reorderedSubMenus = updatedSubMenus.map((sm, idx) => ({
        ...sm,
        position: idx + 1,
      }));

      newMenuItems[menuIndex] = {
        ...item,
        subMenus: reorderedSubMenus,
      };

      setMenuItems(newMenuItems);
    }
  };

  const isValidLink = url =>
    url?.startsWith('https://') || url?.startsWith('/');

  const handleSaveChanges = useCallback(() => {
    // Form validation
    let isValid = true;
    let areLinksValid = true;
    menuItems.forEach(item => {
      if (item.name === '') {
        isValid = false;
      }
      if (item.type === 'dynamic') {
        // Check if links are filled
        if (
          item.subMenus.length > 0 &&
          item.subMenus.some(
            subMenu => !subMenu.url || !isValidLink(subMenu.url),
          )
        ) {
          areLinksValid = false;
        } else if (
          item.subMenus.length === 0 &&
          (!item.url || !isValidLink(item.url))
        ) {
          areLinksValid = false;
        }
      }
      Object.values(item.translations).forEach(v => {
        if (v === '') {
          isValid = false;
        }
      });
    });
    if (isValid && areLinksValid) {
      $('#editOrganizationButton').click();
    } else {
      if (!isValid) {
        Notifications.showNotificationError(
          t('error'),
          t('error.fillTranslations'),
        );
      }
      if (!areLinksValid) {
        Notifications.showNotificationError(t('error'), t('error.fillLinks'));
      }
    }
  }, [menuItems]);

  const [isDraggable, setIsDraggable] = useState(true);

  return (
    <div>
      <input
        type="hidden"
        value={JSON.stringify(menuItems)}
        name="userPortalMenus"
      />
      <h3 className="userPortalMenu-title">
        {t('settings.configuration.general.userPortalMenus')}
      </h3>
      <ul id="userPortalMenus-father-container">
        {menuItems.map((item, index) => (
          <li
            key={item.position}
            draggable={isDraggable}
            onDragStart={e => handleDragStart(e, index)}
            onDragOver={e => e.preventDefault()}
            onDrop={e => handleDrop(e, index)}
          >
            <div style={{ position: 'relative' }}>
              <button
                type="button"
                className="btn btn-circle red delete-menu-button"
                onClick={() => removeMenu(index)}
              >
                <i className="fa fa-times" />
              </button>
              <p className="">Menu {item.position}</p>
              <div style={{ width: '200px' }}>
                <Select
                  id={`menuTypes-${index}-${item.position}`}
                  name={`menuTypes-${index}-${item.position}`}
                  placeholder={t('role_type')}
                  onChange={onFieldChange('type', index)}
                  value={item.type || 'dynamic'}
                  className="form-control m-input col-4"
                  multiple={false}
                  items={menuTypes}
                  width="200px"
                />
              </div>
              <SubMenu
                menu={item}
                index={index}
                defaultLanguage={defaultLanguage}
                organizationLanguages={organizationLanguages}
                t={t}
                menuItems={menuItems}
                setMenuItems={setMenuItems}
                isSubMenu={false}
                onMoveUp={moveSubMenu(item, 'up')}
                onMoveDown={moveSubMenu(item, 'down')}
                setIsDraggable={setIsDraggable}
              />
            </div>
          </li>
        ))}
      </ul>
      <div>
        <button
          type="button"
          onClick={addMenu}
          disabled={menuItems.length >= 6}
          className="btn btn-primary"
        >
          {t('addMenu')}
        </button>
      </div>
      <div className="save-wrapper">
        <button className="btn green" type="button" onClick={handleSaveChanges}>
          <span>{t('save_changes')}</span>
        </button>
      </div>
    </div>
  );
}

OrganizationUserPortalMenus.propTypes = {
  t: PropTypes.func.isRequired,
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  userPortalMenus: MenuType.isRequired,
};
